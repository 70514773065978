import { ActivatedRoute, Router } from '@angular/router';
import { AddOrUpdatePersistenceInput } from '../../models/add-or-update-persistence.interface';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CustomEvent } from 'src/app/shared/models/custom-event.interface';
import { CustomEventsService } from '../../services/custom-events/custom-events.service';
import { CustomEventTokens } from '../../models/custom-event-tokens';
import { GraphqlApisService } from '../../services/graphql-apis/graphql-apis.service';
import { LabelValue } from '../../models/label-value.interface';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { MenuItem } from 'primeng/api';
import { PermissionService } from '../../guards/permission/service/permission.service';
import { PersistenceGraphqlApisService } from '../../services/graphql-apis/persistence-graphql-apis.service';
import { PersistenceSettingsRequest } from '../../models/persistence-settings.interface';
import { SidebarService } from '../../services/dashboard/sidebar.service';
import { Subscription, combineLatest, interval, map } from 'rxjs';
import { SubTenant } from 'src/app/graphql/generated';
import { UserService } from '../../services/user.service';
import { WorkflowGraphqlApisService } from '../../services/graphql-apis/workflow-graphql-apis.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  intervalSubscription: Subscription = new Subscription();
  @Output() closeSidebarEvent = new EventEmitter<any>();
  fundingStatusCount: any[] = [];
  settlementStatusCount?: any;
  collateralStatusCount?: any;
  subTenantId!: string;
  selectedSubTenants: LabelValue[] = [];
  subTenants: LabelValue[] = [];
  isTenantUser = false;
  isCollpaseSidebar: boolean = false;
  @Input() isHideSidebar: boolean = true;
  public intervallTimer = interval(30000);
  originatorValues!: string;
  sideBarMenuItems: MenuItem[] = [
    {
      label: 'Funding',
      icon: 'pi pi-money-bill',
      items: this.getFundingMenuItems(),
      command: () => {
        this.router.navigate(['/secure/funding/funding-pipeline']);
      },
      expanded: false,
    },
    {
      label: 'Collateral',
      icon: 'pi pi-send',
      items: this.getCollateralMenuItems(),
      command: () => {
        this.router.navigate(['/secure/collateral/collateral-pipeline']);
      },
      expanded: false,
    },
    {
      label: 'Settlement',
      icon: 'pi pi-dollar',
      items: this.getSettlementMenuItems(),
      command: () => {
        this.router.navigate(['/secure/settlement/settlement-landing']);
      },
      expanded: false,
    },
  ];

  sideBarItemsToggleList: any = {
    Funding: true,
    Collateral: true,
    Settlement: true,
  };

  constructor(
    private graphqlApiService: GraphqlApisService,
    private userService: UserService,
    private router: Router,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private persistenceService: PersistenceGraphqlApisService,
    private localStorageService: LocalStorageService,
    private customEventsService: CustomEventsService,
    private permissionService: PermissionService,
    private workflowGraphqlApisService: WorkflowGraphqlApisService
  ) {}

  ngOnInit(): void {
    this.getAllSubTenants();
    this.initSideBarMenuItems();
    this.subTenantId = this.userService.subTenantId;
    this.isTenantUser = this.userService.isTenant;
    this.setSidebarFilterOptions();
    let customEventServiceSubscription = this.customEventsService
      .getEvent()
      .subscribe((customEvent: CustomEvent) => {
        if (customEvent.name === CustomEventTokens.NEW_IMB) {
          this.getAllSubTenants();
        }
      });
    this.subscription.add(customEventServiceSubscription);
  }

  getPersistenceSettings() {
    const input: PersistenceSettingsRequest = {
      origin: 'User',
      originId: this.userService.claims.userId,
      originType: 'GLOBAL',
    };

    const persistenceSubscription = this.persistenceService
      .getPersistenceSettings(input)
      .subscribe((data) => {
        if (data?.data?.persistence?.length) {
          const agilityUser = this.localStorageService.getLocalStorageItem(
            CustomEventTokens.AGILITY_USER
          );
          agilityUser.selectedOriginators = JSON.parse(
            data?.data?.persistence[0].persistenceJson
          ).selectedOriginators;
          this.localStorageService.createLocalStorageItem(
            CustomEventTokens.AGILITY_USER,
            agilityUser
          );
          this.selectedSubTenants = [];
          agilityUser.selectedOriginators.forEach((originator: LabelValue) => {
            let selectedSubtenant = this.subTenants.find(
              (st: any) => st.value === originator.value
            );
            if (selectedSubtenant) {
              this.selectedSubTenants.push(selectedSubtenant);
            }
          });
          this.setSelectedIMBs();
        }
      });
    this.subscription.add(persistenceSubscription);
  }

  sideBarItems() {
    this.sideBarMenuItems = [
      {
        label: 'Funding',
        icon: 'pi pi-money-bill',
        items: this.getFundingMenuItems(),
        command: (event) => {
          if (!this.isPanelMenuIconClick(event)) {
            this.router.navigate(['/secure/funding/funding-pipeline']);
          }
        },
        expanded: this.sideBarItemsToggleList['Funding'],
      },
      {
        label: 'Collateral',
        icon: 'pi pi-send',
        items: this.getCollateralMenuItems(),
        command: (event) => {
          if (!this.isPanelMenuIconClick(event)) {
            this.router.navigate(['/secure/collateral/collateral-pipeline']);
          }
        },
        expanded: this.sideBarItemsToggleList['Collateral'],
      },
      {
        label: 'Settlement',
        icon: 'pi pi-dollar',
        items: this.getSettlementMenuItems(),
        command: (event) => {
          if (!this.isPanelMenuIconClick(event)) {
            this.router.navigate(['/secure/settlement/settlement-landing']);
          }
        },
        expanded: this.sideBarItemsToggleList['Settlement'],
      },
    ];
  }

  get isAdminPage() {
    return this.router.url.indexOf('/secure/admin') > -1;
  }

  isPanelMenuIconClick(event: any): boolean {
    const target = event?.originalEvent?.target as HTMLElement;
    return target?.classList?.contains('p-panelmenu-icon');
  }

  getAllSubTenants() {
    if (this.userService.isTenant) {
      this.graphqlApiService
        .getAllSubTenants()
        .pipe(
          map(({ data: { subTenants } }) => {
            return (
              subTenants
                // Map subTenants array to LabelValue shape
                .map((subTenant: SubTenant) => ({
                  label: subTenant.originatorName,
                  value: subTenant.subTenantId,
                }))
                // Sort alphabetically by label
                .sort((a: LabelValue, b: LabelValue) =>
                  a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                )
            );
          })
        )
        .subscribe((data: LabelValue[]) => {
          if (data) {
            this.subTenants = data;
            this.selectedSubTenants = [...data];
            this.setSelectedIMBs();
            this.getPersistenceSettings();
          }
        });
    }
  }

  setSelectedIMBs() {
    this.sidebarService.setSelectedIMBs(
      this.selectedSubTenants.length === 0
        ? this.subTenants
        : this.selectedSubTenants
    );
  }

  getFundingStatusCountByName(status: string) {
    const row = this.fundingStatusCount?.find((e) => e.status == status);
    return row ? row?.count.toString() : '0';
  }

  getSettlementStatusCountByName(status: string) {
    if (this.settlementStatusCount && this.settlementStatusCount != undefined) {
      return this.settlementStatusCount[status];
    } else {
      return '0';
    }
  }

  getCollateralStatusCountByName(status: string) {
    const row = this.collateralStatusCount?.find(
      (e: any) => e.status == status
    );
    return row ? row?.count.toString() : '0';
  }

  getFundingStatusAlertByName(status: string) {
    const row = this.fundingStatusCount?.find((e) => e.status == status);
    return row?.highPriorityIndicator
      ? 'pi pi-fw pi-exclamation-circle color-danger m-0'
      : '';
  }

  getFundingMenuItems() {
    let list = [];
    if (this.userService.isTenant) {
      list = [
        {
          label: 'Pending Approval',
          badge: this.getFundingStatusCountByName('Pending Approval'),
          icon: this.getFundingStatusAlertByName('Pending Approval'),
          routerLink: '/secure/funding',
          queryParams: {
            loanStatus: 'Pending Approval',
          },
        },

        {
          label: 'Approved',
          badge: this.getFundingStatusCountByName('Approved'),
          icon: this.getFundingStatusAlertByName('Approved'),
          routerLink: '/secure/funding',
          queryParams: {
            loanStatus: 'Approved',
          },
        },
        {
          label: 'Processing',
          badge: this.getFundingStatusCountByName('Processing'),
          icon: this.getFundingStatusAlertByName('Processing'),
          routerLink: '/secure/funding',
          queryParams: {
            loanStatus: 'Processing',
          },
        },
      ];
    } else {
      list = [
        {
          label: 'New',
          badge: this.getFundingStatusCountByName('New'),
          icon: this.getFundingStatusAlertByName('New'),
          routerLink: '/secure/funding',
          queryParams: { loanStatus: 'New' },
        },
        {
          label: 'Returned',
          badge: this.getFundingStatusCountByName('Returned'),
          icon: this.getFundingStatusAlertByName('Returned'),
          routerLink: '/secure/funding',
          queryParams: {
            loanStatus: 'Returned',
          },
        },
      ];
    }
    return list.filter((e) => e.badge != '0');
  }

  setSidebarFilterOptions() {
    const selectedSubTenants = this.selectedSubTenants.length
      ? this.selectedSubTenants
      : this.subTenants;
    const selectedOriginators = selectedSubTenants?.map(
      (originator) => originator.label
    );
    const allOriginators = this.subTenants.map((org: any) => org.label);
    this.originatorValues =
      selectedOriginators.length >= 1
        ? selectedOriginators.join(',')
        : allOriginators.join(',');
  }

  customCallbackFilter() {
    this.setSelectedIMBs();
    this.initSideBarMenuItems();
    this.setSidebarFilterOptions();
    this.addOrUpdatePersistence();

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { loanStatus: '', originator: this.originatorValues },
    });
  }
  addOrUpdatePersistence() {
    let originators: LabelValue[] = [];
    const selectedSubTenants = this.selectedSubTenants.length
      ? this.selectedSubTenants
      : this.subTenants;
    selectedSubTenants.forEach((originator) => {
      originators.push({
        label: originator.label,
        value: originator.value,
      });
    });
    const input: AddOrUpdatePersistenceInput = {
      origin: 'User',
      originId: this.userService.claims.userId,
      originType: 'GLOBAL',
      persistenceJson: JSON.stringify({ selectedOriginators: originators }),
    };
    const persistenceSubscription = this.persistenceService
      .addOrUpdatePersistence(input)
      .subscribe((data) => {
        const agilityUser = this.localStorageService.getLocalStorageItem(
          CustomEventTokens.AGILITY_USER
        );
        agilityUser.selectedOriginators = originators;
        this.localStorageService.createLocalStorageItem(
          CustomEventTokens.AGILITY_USER,
          agilityUser
        );
      });

    this.subscription.add(persistenceSubscription);
  }

  getWorkflowCount() {
    let subTenantIds: any = [];
    if (this.userService.isSubTenant) {
      subTenantIds = [this.userService.subTenantId];
    } else {
      subTenantIds = this.selectedSubTenants.map((ele) => ele.value);
    }
    const fundingStatusCount$ =
      this.workflowGraphqlApisService.getFundingWorkflow(subTenantIds);
    const settlementStatusCount$ =
      this.workflowGraphqlApisService.getSettlementWorkflow(subTenantIds);
    const collateralStatusCount$ = this.permissionService.hasPermission({
      permission: 'COLLATERAL_DASHBOARD',
    })
      ? this.workflowGraphqlApisService.getCollateralWorkflow(subTenantIds)
      : undefined;

    combineLatest(
      [
        fundingStatusCount$,
        settlementStatusCount$,
        collateralStatusCount$,
      ].filter((sub) => sub !== undefined)
    )?.subscribe(
      ([
        fundingStatusCountResponse,
        settlementStatusCountResponse,
        collateralStatusCountResponse,
      ]: any) => {
        if (fundingStatusCountResponse.data?.fundingWorkflow) {
          this.fundingStatusCount = [
            ...fundingStatusCountResponse.data.fundingWorkflow,
          ];
        }

        if (settlementStatusCountResponse.data?.settlementWorkflow) {
          this.settlementStatusCount =
            settlementStatusCountResponse.data.settlementWorkflow;
        }

        if (collateralStatusCountResponse?.data?.collateralWorkflow) {
          this.collateralStatusCount = [
            ...collateralStatusCountResponse.data.collateralWorkflow,
          ];
        }

        this.sideBarItems();

        this.sideBarMenuItems.forEach((item: MenuItem) => {
          const label: any = item.label;
          this.sideBarItemsToggleList[label] = item.expanded ? true : false;
        });
      }
    );
  }

  initSideBarMenuItems() {
    if (!this.userService.isSuperTenant) {
      this.getWorkflowCount();
      this.intervalSubscription = this.intervallTimer.subscribe(() => {
        if (!this.isCollpaseSidebar && !this.isAdminPage) {
          this.getWorkflowCount();
        }
      });
    }
  }

  getSettlementMenuItems() {
    let list = [];
    if (this.userService.isTenant) {
      list = [
        {
          label: 'Funds Received',
          badge: this.getSettlementStatusCountByName('fundsReceived'),
          routerLink: '/secure/settlement',
          queryParams: {
            workflowStatus: 'fundsReceived',
          },
        },
        {
          label: 'Settlement Request',
          badge: this.getSettlementStatusCountByName('settlementRequest'),
          routerLink: '/secure/settlement',
          queryParams: {
            workflowStatus: 'settlementRequest',
          },
        },
        {
          label: 'Unidentified',
          badge: this.getSettlementStatusCountByName('unIdentified'),
          routerLink: '/secure/settlement',
          queryParams: {
            workflowStatus: 'unidentified',
          },
        },
        {
          label: 'Returned',
          badge: this.getSettlementStatusCountByName('returned'),
          routerLink: '/secure/settlement',
          queryParams: {
            workflowStatus: 'returned',
          },
        },
        {
          label: 'Pending Confirmation',
          badge: this.getSettlementStatusCountByName('pendingConfirmation'),
          routerLink: '/secure/settlement',
          queryParams: {
            workflowStatus: 'pendingConfirmation',
          },
        },
      ];
    } else {
      list = [
        {
          label: 'Funds Received',
          badge: this.getSettlementStatusCountByName('fundsReceived'),
          routerLink: '/secure/settlement',
          queryParams: {
            workflowStatus: 'fundsReceived',
          },
        },
        {
          label: 'Settlement Request',
          badge: this.getSettlementStatusCountByName('settlementRequest'),
          routerLink: '/secure/settlement',
          queryParams: {
            workflowStatus: 'settlementRequest',
          },
        },
        {
          label: 'Returned',
          badge: this.getSettlementStatusCountByName('returned'),
          routerLink: '/secure/settlement',
          queryParams: {
            workflowStatus: 'returned',
          },
        },
      ];
    }
    return list.filter((e) => e.badge != '0');
  }

  getCollateralMenuItems() {
    let list = [];
    if (this.userService.isTenant) {
      list = [
        {
          label: 'Wet',
          badge: this.getCollateralStatusCountByName('WetLoans'),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'wet',
          },
        },
        {
          label: 'In Review',
          badge: this.getCollateralStatusCountByName('In Review'),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'inReview',
          },
        },
        {
          label: 'Pending Investor',
          badge: this.getCollateralStatusCountByName('Pending Investor'),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'pendingInvestor',
          },
        },
        {
          label: 'Shipping Instructions',
          badge: this.getCollateralStatusCountByName(
            'Pending Shipping Instructions'
          ),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'pendingShippingInstructions',
          },
        },
        {
          label: 'Collateral Returned',
          badge: this.getCollateralStatusCountByName('Collateral Returned'),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'collateralReturned',
          },
        },
        {
          label: 'Ready to Ship',
          badge: this.getCollateralStatusCountByName('Ready to Ship'),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'readyToShip',
          },
        },
        {
          label: 'Not Accepted',
          badge: this.getCollateralStatusCountByName('Not Accepted'),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'notAccepted',
          },
        },
      ];
    } else {
      list = [
        {
          label: 'Wet',
          badge: this.getCollateralStatusCountByName('WetLoans'),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'wet',
          },
        },
        {
          label: 'In Review',
          badge: this.getCollateralStatusCountByName('In Review'),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'inReview',
          },
        },
        {
          label: 'Pending Investor',
          badge: this.getCollateralStatusCountByName('Pending Investor'),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'pendingInvestor',
          },
        },
        {
          label: 'Shipping Instructions',
          badge: this.getCollateralStatusCountByName(
            'Pending Shipping Instructions'
          ),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'pendingShippingInstructions',
          },
        },
        {
          label: 'Collateral Returned',
          badge: this.getCollateralStatusCountByName('Collateral Returned'),
          routerLink: '/secure/collateral/collateral-pipeline',
          queryParams: {
            workflowStatus: 'collateralReturned',
          },
        },
      ];
    }
    return list.filter((e) => e.badge != '0');
  }

  get isShowLoanSearch() {
    return this.router.url.indexOf('/secure/funding/loan-request') > -1;
  }
  closeSidebar() {
    this.isCollpaseSidebar = true;
    this.closeSidebarEvent.emit(this.isCollpaseSidebar);
  }
  hideSidebar() {
    this.isCollpaseSidebar = false;
    this.closeSidebarEvent.emit(this.isCollpaseSidebar);
  }
  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }
}
